module.exports = {
  siteTitle: 'TalkInGeorgian.com', // <title>
  manifestName: 'TalkInGeorgian.com',
  manifestShortName: 'TIG', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-eventually/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  heading: 'Learning the Georgian language (the country not the state)?',
  subHeading: "We're putting together something you'll love. Subscribe for updates. (no spam)",
  socialLinks: [
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/mezzofanti',
    },
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://facebook.com/mezzoguild',
    },
  ],
};
